.inputQuantity {

  display: inline-flex;

  button, input {
    height: 38px;
  }

  input {
    border-radius: 0;
    border: 1px solid rgba(34,36,38,.15);
    border-right-color: transparent!important;
    border-left-color: transparent!important;
    text-align: center;
    width: 60px;
  }
  button {
    border: none;
  }

  div {
    border: 1px solid rgba(34,36,38,.15) !important;
    padding: 10px;
    cursor: default;
  }
}
.buttonLeft {
  border-top-left-radius: .28571429rem;
  border-bottom-left-radius: .28571429rem;
  margin-right: 0 !important;
}

.buttonRight {
  border-radius: 0 .28571429rem .28571429rem 0;
  margin-left: 0;
}
