.container {
  background-image: url("https://d2d1lrp4ajswkh.cloudfront.net/_assets/header_1.jpg");
  height: 250px;
  width: 100%;
  position: relative;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, .3);
  z-index: 1
}

.content {
  z-index: 2;
  position: absolute;
  background-color: white;
  min-width: 350px;
  top: 50px;
  max-height: 200px;
  padding: 10px 20px;
}
